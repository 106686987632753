<template>
  <div class="stockManagement content-index">
    <div class="flex-between" style="padding-bottom:10px">
      <div class="flex-row" style="cursor:pointer" @click="back">
        <i class="el-icon-back" style="font-size:18px;color:#000"></i>
        <span style="padding-left:10px">商品入库</span>
      </div>
      <el-button size="medium" type="primary" @click="into">商品入库</el-button>
    </div>
    <div class="search">
      <Search :search-data="searchData" @searchChange="searchChange" />
    </div>
    <div v-loading="tableLoading" class="table-box">
      <Table
        :button-type="true"
        :button-width="100"
        :current-value="formData.current"
        :header-style="headerStyle"
        :height="''"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @operationClick="operationClick"
      />
    </div>
    <IntoAndOut
      :dialog-visible="dialogVisible1"
      :enter-text="'入库'"
      :header-style="headerStyle"
      :into-type-list="inventoryTypeList"
      :title-list-data="intoTitleListData"
      @close="closeDialog"
      @enter="enterIntoAndOut"
    />
  </div>
</template>

<script>
import Search from "@/components/search";
import Table from "@/components/table2";
import IntoAndOut from "../components/intoAndOut";
import { getInventoryRecordPage } from "@/api/commodity";
import { mapGetters } from "vuex";

export default {
  name: "StockManagement",
  components: {
    Search,
    Table,
    IntoAndOut,
  },
  data() {
    return {
      searchData: [
        {
          prop: "recordNumber",
          label: "入库单号",
          type: "text",
          labelWidth: "80px",
        },
        {
          prop: "createdTime",
          label: "入库时间",
          type: "DateTime",
          labelWidth: "80px",
        },
        {
          prop: "inventoryType",
          label: "入库类型",
          labelWidth: "80px",
          type: "select",
          selectArr: [
            {
              name: "采购入库",
              id: 1,
            },
            {
              name: "退货入库",
              id: 2,
            },
            {
              name: "其他入库",
              id: 3,
            },
            {
              name: "残次品转良品",
              id: 4,
            },
          ],
        },
        {
          labelWidth: "20px",
          typeList: [
            {
              name: "查询",
              code: "search",
              type: "primary",
            },
            {
              name: "重置",
              code: "reset",
            },
          ],
        },
      ],
      inventoryTypeList: [
        {
          name: "采购入库",
          id: 1,
        },
        {
          name: "退货入库",
          id: 2,
        },
        {
          name: "其他入库",
          id: 3,
        },
        {
          name: "残次品转良品",
          id: 4,
        },
      ],
      formData: {
        current: 1,
        size: 10,
        recordType: 1,
        operateType: 2,
      },
      headerStyle: {
        background: "#f5f7fa",
        height: "50px",
      },
      total: 0,
      tableData: [],
      titleListData: [
        {
          prop: "recordNumber",
          label: "入库单号",
        },
        {
          prop: "inventoryTypeDesc",
          label: "入库类型",
        },
        {
          prop: "createdTime",
          label: "入库时间",
          width: 150,
        },
        {
          prop: "remark",
          label: "备注",
        },
        {
          prop: "operateUserName",
          label: "入库人",
        },
      ],
      dialogVisible1: false,
      intoForm: {},
      intoTitleListData: [
        {
          prop: "goodsName",
          label: "商品名称",
          width: 140,
        },
        {
          prop: "specification",
          label: "商品规格",
        },
        {
          prop: "barcode",
          label: "规格条码",
          width: 120,
        },
        {
          prop: "orderGoodsUnit",
          label: "单位",
        },
        {
          prop: "num",
          label: "入库数量",
          width: 120,
          type: "slot",
        },
      ],
      showDrawer: false,
      tableLoading: false,
      detailsId: "",
    };
  },
  created() {
    this.formData.storeId=this.storeUserInfo.storeId
    this.getInventoryRecordList();
  },
  computed: {
    ...mapGetters({
      storeUserInfo: "userInfo",
    }),
  },
  methods: {
    back() {
      this.$router.back();
    },
    // 获取列表数据
    getInventoryRecordList() {
      this.tableLoading = true;
      getInventoryRecordPage({ ...this.formData })
        .then(({ data }) => {
          if (data.code == 0) {
            this.tableData = data.data.records.map((item) => {
              item.buttonList = [
                {
                  name: "详情",
                  size: "medium",
                  type: "text",
                  code: "details",
                  width: "100",
                },
              ];
              return item;
            });
            this.total = data.data.total;
          } else {
            this.$message.error(data.msg);
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    into() {
      this.dialogVisible1 = true;
      console.log("商品入库");
    },
    // 搜索内容触发
    searchChange({ code, formData }) {
      if (code == "search") {
        this.formData = {
          ...this.formData,
          current: 1,
          recordNumber: formData.recordNumber,
          beginTime:
            formData.createdTime && formData.createdTime.length != 0
              ? formData.createdTime[0]
              : null,
          endTime:
            formData.createdTime && formData.createdTime.length != 0
              ? formData.createdTime[1]
              : null,
          inventoryType: formData.inventoryType,
        };
      } else if (code == "reset") {
        this.formData = {
          current: 1,
          size: 10,
          recordType: 1,
          operateType: 2,
          storeId:this.storeUserInfo.storeId
        };
      }
      this.getInventoryRecordList();
    },
    // 表格分页触发
    handleCurrentChange(value) {
      this.formData.current = value;
      this.getInventoryRecordList();
    },
    // 点击表格按钮触发
    operationClick({ row, code }) {
      if (code == "details") {
        this.$router.push({
          path: "/home/more/commodityDetails",
          query: {
            text: "入库",
            detailsId: row.inventoryRecordId,
          },
        });
      }
    },
    // 关闭商品入库弹框
    closeDialog() {
      this.dialogVisible1 = false;
    },
    // 确定商品入库
    enterIntoAndOut() {
      this.dialogVisible1 = false;
      this.getInventoryRecordList();
    },
  },
};
</script>

<style lang="scss" scoped>
.stockManagement {
  padding: 20px;
  overflow: auto;
}
.search {
  margin: 10px 0;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row {
  display: flex;
  align-items: center;
}
::v-deep .el-table__body td.el-table__cell {
  height: 50px;
}
</style>
